<template>
    <div class="overview">
<!--        <div style="padding: 30px 50px; border-bottom: 1px #CCCCCC solid; font-size: 20px; font-weight: 600">-->
<!--            <span style="margin-right: 70px">账户：{{user.accoun}}</span>-->
<!--            <span style="margin-right: 70px">账户安全指数：-->
<!--                <span style="display: inline-block; width: 108px; height: 12px; border-radius: 30px; background-color: #69AA00; margin-right: 10px"></span>-->
<!--                <span style="color: #69AA00; font-size: 16px">高</span>-->
<!--            </span>-->
<!--            <span>上次登录时间：{{user.lastLoginTime}}</span>-->
<!--        </div>-->

        <div style="display: flex">
            <div class="body-flex">
                <div class="tag-flex">
                  <div class="tag-box hand op" @click="clickPull(1)" :class="pull === 1 ? 'select-tag-pull' : ''">累计收益</div>
                  <div class="tag-box hand op" @click="clickPull(2)" :class="pull === 2 ? 'select-tag-pull' : ''">年收益</div>
                  <div class="tag-box hand op" @click="clickPull(3)" :class="pull === 3 ? 'select-tag-pull' : ''">月收益</div>
                </div>
                <div class="circle" style="border-color: #69AA00;">
                    <div class="circle-title">总收益（元）</div>
                    <div class="circle-content">+{{lletflow.totalAmount}}</div>
                </div>

                <div style="width: 100%;">
                    <div v-for="(data, index) in lletflow.list" :key="index" style="margin-bottom: 40px; display: flex">
                        <div style="width: 18px; height: 18px; border-radius: 50%;" :style="{'background-color': pullColors[index % pullColors.length]}"></div>
                        <div style="width: 16%; text-align: center; margin: 0 10px">{{ data.name }}</div>
                        <div style="width: 56%; margin-right: 10px">
                            <el-progress
                                    :text-inside="true"
                                    :stroke-width="18"
                                    :percentage="convertToNumber(data.percent)"
                                    :color="pullColors[index % pullColors.length]"
                                    class="progress"
                            ></el-progress>
                        </div>
                        <div>{{data.value}}元</div>
                    </div>
                </div>
            </div>
            <div class="body-flex">
              <div class="tag-flex">
                <div class="tag-box hand op" @click="clickPush(1)" :class="push === 1 ? 'select-tag-push' : ''">累计支出</div>
                <div class="tag-box hand op" @click="clickPush(2)" :class="push === 2 ? 'select-tag-push' : ''">年支出</div>
                <div class="tag-box hand op" @click="clickPush(3)" :class="push === 3 ? 'select-tag-push' : ''">月支出</div>
              </div>
                <div class="circle" style="border-color: #EE3F4D">
                    <div class="circle-title">总支出（元）</div>
                    <div class="circle-content">-{{lletflow_enue.totalAmount}}</div>
                </div>

                <div style="width: 100%;">
                    <div v-for="(data, index) in lletflow_enue.list" :key="index" style="margin-bottom: 40px; display: flex">
                        <div style="width: 18px; height: 18px; border-radius: 50%;" :style="{'background-color': pushColors[index % pushColors.length]}"></div>
                        <div style="width: 16%; text-align: center; margin: 0 10px">{{ data.name }}</div>
                        <div style="width: 56%; margin-right: 10px">
                            <el-progress
                                    :text-inside="true"
                                    :stroke-width="18"
                                    :percentage="convertToNumber(data.percent)"
                                    :color="pushColors[index % pushColors.length]"
                                    class="progress"
                            ></el-progress>
                        </div>
                        <div>{{data.value}}元</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Overview",
        computed: {
          user() {
            return this.$store.state.user
          },
          is_login(){
            return this.$store.state.is_login
          },
        },
        data() {
            return {
                pull: 1,
                push: 1,
                chartData: [],

                totalValue: 1,
                pushColors: ['#F57683', '#D96C6F', '#CB3958', '#E43726', '#F4725A', '#F54336', '#C92C1D', '#C15865'],
                pullColors: ['#B2DA5F', '#CDE461', '#6ECC8F', '#549F71', '#2E8F69', '#95CA00', '#73A02A', '#83B257'],
                lletflow:{},
                lletflow_enue:{},
                percent: "0.00",

            }
        },

        mounted() {
            this.calculateTotalValue();
            this.walletFlow()
        },

        methods: {
            //收益时间筛选
            clickPull(index){
                this.pull = index;
                this.walletFlow();
            },
            //支出时间筛选
            clickPush(index){
                this.push = index;
                this.walletFlow();
            },
            calculateTotalValue: function() {
                this.totalValue = this.chartData.reduce((total, data) => total + data.value, 0);
            },

            walletFlow(){
                let that = this;
                that.newApi.walletFlow({ income:that.pull,expenditure:that.push}).then((res)=>{
                    that.lletflow = res.data.totalRevenue;
                    that.lletflow_enue = res.data.totalExpenditure;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            convertToNumber(value) {
                return parseFloat(value); // 使用parseFloat将字符串转换为浮点数
            }

        },
    }
</script>

<style scoped>
    .overview .body-flex {
        width: 50%;
        padding: 30px 60px;
    }

    .overview .circle {
        width: 302px;
        height: 302px;
        border-radius: 50%;
        margin-bottom: 40px;
        margin-left: 60px;
        text-align: center;
        border: 16px #FFFFFF solid;
    }

    .overview .circle-title {
        font-size: 20px;
        font-weight: 600;
        color: #999999;
        margin: 100px 0 20px 20px;
    }

    .overview .circle-content {
        font-size: 40px;
        font-weight: 600;
    }

    .overview .tag-flex {
      width: 430px;
      height: 40px;
      display: flex;
      padding: 4px;
      font-size: 18px;
      font-weight: 600;
      color: #A6A9B7;
      margin-bottom: 40px;
      border-radius: 5px;
      border: 1px solid #CCCCCC;
    }

    .overview .tag-box {
      text-align: center;
      flex: 1;
      border-radius: 5px;
      line-height: 40px;
    }

    .overview .select-tag-pull {
      background-color: #69AA00;
      color: #FFFFFF;
    }

    .overview .select-tag-push {
      background-color: #EE3F4D;
      color: #FFFFFF;
    }
</style>
